import { Message } from 'element-ui'
function verificationPWFun (username, login_passwd_new){
    // 字符范围
     if(login_passwd_new.length<8||login_passwd_new.length>20){
        Message.error("字符数量不符")
        return false;
    }
   //不能连续字符（如12、ab）连续2位或2位以上
    if(!LxSt(login_passwd_new)){
        Message.error("请不要包含连续三位字符")
        return false;
    }
    //不能相同字符（如11、aa）连续2位或2位以上
    var re = /(\w)*(\w)\2{2}(\w)*/g;
    if(re.test(login_passwd_new)){
        Message.error("请不要包含连续三位相同字符")
        return false;
    }
    // 包含字母，数字
    // var reg = /^(\d+[a-zA-Z]+|[a-zA-Z]+\d+)([0-9a-zA-Z]*)$/
    var reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/

    if(!reg.test(login_passwd_new)){
        Message.error("请同时包含大小写字母和数字")
        return false;
    }
    // 不允许使用username里任意相邻的 4 个字符
    if(!userpass(username,login_passwd_new)){
        Message.error("请不要与用户名里任意相邻的 4 个字符相同")
        return false;
    }
    return true;
}

//不能连续字符（如123、abc）连续3位或3位以上
function LxSt(str){
    var arr = str.split('');
    var flag = true;
    for (var i = 1; i < arr.length-1; i++) {
        var firstIndex = arr[i-1].charCodeAt();
        var secondIndex = arr[i].charCodeAt();
        var threeIndex = arr[i+1].charCodeAt();
        if(secondIndex - firstIndex==1&&threeIndex-secondIndex ==1){
            flag =  false;
            return flag;
        }
    }
    return flag;
}

// 不允许使用username里任意相邻的 4 个字符
function userpass(username,str){
    username = username + '';
    for (var i = 0; i < str.length-3; i++) {
        var nowStr = str.substr(i, 4)
        if(username.indexOf(nowStr) !== -1){
           return false;
        }
    }
    return true;
}


export default verificationPWFun;