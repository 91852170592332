<template>
  <div class="changePassword">
    <p class="title">修改密码</p>
    <p v-if="is_redirect == 1" class="tip">首次登陆，请修改默认密码</p>
    <p v-else-if="is_redirect == 2" class="tip">密码使用时间过长，请修改一次密码</p>
    <div class="content">
        <div class="body_left">
            <el-row :gutter="20">
                <el-col :span="10"><div>原密码：</div></el-col>
                <el-col :span="6"><el-input v-model="oldPassWd" placeholder="请输入原密码" type="password" show-password></el-input></el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="10"><div>密码：</div></el-col>
                <el-col :span="6"><el-input v-model="newPassWd" placeholder="请输入修改后的密码" type="password" show-password></el-input></el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="10"><div>确认密码：</div></el-col>
                <el-col :span="6"><el-input v-model="confirmPassWd" placeholder="请确认修改后的密码" type="password" show-password></el-input></el-col>
            </el-row>
        </div>
        <div class="body_rigth">
            <div class="body_rigth_text">密码请设置为<span>8-20</span> 位，必须包含<span>英文（大小写都有） + 数字</span>，数字和英文不允许<span>连续三位相同或连续三位排列</span>（如aaa、abc、111、123），不允许使用用户名里任意相邻的 4 个字符</div>
        </div>
    </div>
     <div class="bottomBtn">
        <el-button type="info" @click="cancel">取消</el-button>
        <el-button type="success" @click="change_passwordFun">保存</el-button>
    </div>
  </div>
</template>
<script>
import apiDetail from '@/api/other.js'
import api from '@/api/login.js'
import verificationPWFun from '@/assets/js/verificationPW'
import { removeToken } from '@/utils/auth'
import { Message } from 'element-ui'

export default {
  data() {
    return {
      oldPassWd: "",
      newPassWd: "",
      confirmPassWd: "" ,
      phone: "",
      is_redirect: 0,
   }
  },
  mounted() {
    this.getUserInfor();
    this.is_redirect = this.$route.query.is_redirect
  },
  methods: {
    //  获取个人信息
    getUserInfor() {
        api.userinfo().then(res => {
           this.phone = res.data.phone
        })
    },
    // 退回上级页面
    cancel() {
        this.$router.go(-1)
    },
    change_passwordFun() {
        if(verificationPWFun(this.phone,this.newPassWd)){
            if (this.newPassWd != this.confirmPassWd) {
                Message.error("密码不一致，请确认您的输入")
            }else{
                let params = {
                    password: this.oldPassWd,
                    new_password: this.newPassWd
                }                   
                apiDetail.change_password(params).then(res => {
                    Message.success("修改成功，请重新登录")
                    setTimeout(() => {
                        removeToken()
                        window.localStorage.clear()
                        this.$router.push({ name: 'login' })
                    }, 1000);
                })
            }
        }
        
    }
  },
}
</script>
<style lang="scss" scoped>
.changePassword {
    // padding-top: 20px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: url(../../assets/img/bg.png);
    .title {
        margin-left: 5%;
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #111111;
        margin-bottom: 20px;
    }
    .tip {
        margin: 10px 5%;
        color: red;
    }
    .content {
        width: 90%;
        margin-left: 5%;
        background: #fff;
        border-radius: 5px;
        padding: 20px;
        display: flex;
        flex-flow: row wrap;
        .el-row {
            margin-bottom: 20px;
            font-size: 17px;
            text-align: right;
            line-height: 40px;
            ::v-deep .el-input,
            ::v-deep .el-input__inner {
                width: px(400);
                height: 40px;
            }
        }
        .body_left {
            width: 50%;
            display: inline-block;
        }
        .body_rigth {
            width: 45%;
            display: inline-block;
            position: relative;
            .body_rigth_text{
                width: 300px;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                position: absolute;
                span{
                    color: red;
                }
            }
            
        }
    }
    .bottomBtn {
        width: 90%;
        margin-top: 100px;
        text-align: right;
        margin-right: 200px;
    }
}
</style>